import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw from 'twin.macro'
import { ImageType, StyleType } from '../utils/prop-types'
import { ReactComponent as SearchIcon } from '../images/search.svg'
import Image from './image'

const SearchBar = ({ style, onSearch, icon, searchPlaceholder }) => {
  const [inputText, setInputText] = useState('')

  const inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
    onSearch(lowerCase)
  }

  return (
    <>
      <div css={[tw`relative`, style]}>
        <input
          type="input"
          placeholder={searchPlaceholder}
          css={tw`py-6 px-6 w-full h-11 lg:h-20`}
          onChange={inputHandler}
        ></input>
        <div role="button" css={tw`absolute inset-y-4 right-4`} onClick={inputHandler} >
          <Image image={icon} style={tw`w-5 h-5 lg:(w-10 h-10)`} />
        </div>
      </div>
    </>
  )
}

SearchBar.propTypes = {
  image: ImageType,
  imageMobile: ImageType,
  style: StyleType,
  alt: PropTypes.string,
  icon:PropTypes.object,
  props: PropTypes.array,
  searchPlaceholder: PropTypes.string,
}

export default SearchBar
